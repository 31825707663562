<template>
    <el-container class="page-container">
        <!--<el-header class="page-header" style="min-height: 60px;height: auto">-->
         <!---->

        <!--</el-header>-->
        <el-main class="page-main" style="padding: 0 20px">
<!--            <el-row class="row-btn-group">-->
<!--                <el-button type="primary" @click="handleAdd('新增')">-->
<!--                    <i class="cw-icon cw-icon-tianjia"></i>新增-->
<!--                </el-button>-->
<!--                <el-button type="danger" @click="doDelMore" :disabled="selectData.length==0">-->
<!--                    <i class="cw-icon cw-icon-tianjia"></i>删除选中-->
<!--                </el-button>-->
<!--            </el-row>-->
            <br>
            <el-form label-position='left' label-width="75px" :model="query" ref="searchForm">
                <el-row :gutter="20">
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label="时间:">
                            <el-date-picker
                                    v-model="time"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    style="width: auto"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item prop="status" label="网吧:">
                            <el-select v-model="query.shopId" placeholder="请选择网吧"
                                       class="inner-input">
                                <el-option
                                        v-for="item in shopDataArr"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item prop="status" label="返点状态:">
                            <el-select v-model="query.type" placeholder="请选择状态"
                                       class="inner-input">
                                <el-option label="已返点" value="1"></el-option>
                                <el-option label="未返点" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label-width="0">
                            <el-button type="primary" @click="handleSearch">
                                <i class="cw-icon cw-icon-sousuo"></i>搜索
                            </el-button>
                            <el-button type="primary" plain @click="clearSearch">
                                <i class="cw-icon cw-icon-zhongzhi"></i>重置
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>
            <el-table
                    :data="tableData"
                    ref="multipleTable"
                    @selection-change="handleSelectionChange"
                    style="width: 100%">
<!--                <el-table-column-->
<!--                        type="selection"-->
<!--                        width="55">-->
<!--                </el-table-column>-->
                <el-table-column
                        prop="id"
                        label="ID"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="sitename"
                        label="餐点"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="shopname"
                        label="网吧名称"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="order_no"
                        label="订单交易号"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="fee"
                        label="返点值"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="type"
                        label="返点状态"
                        :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <p v-if="scope.row.type==1">已返点</p>
                        <p v-if="scope.row.type==2">未返点</p>
                    </template>
                </el-table-column>

                <el-table-column
                        prop="create_time"
                        label="创建时间"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
<!--                <el-table-column-->
<!--                        fixed="right"-->
<!--                        width="260"-->
<!--                        label="操作">-->
<!--                    <template slot-scope="scope">-->
<!--                        <el-button size="mini" type="success" @click="getErcode(scope.row)"  plain>获取小程序码</el-button>-->
<!--                        <el-button size="mini" type="success" @click="handleEdit(scope.row)" plain>修改</el-button>-->
<!--                        <el-button size="mini" type="success" @click="doDelete(scope.row)" plain>删除</el-button>-->
<!--                    </template>-->
<!--                </el-table-column>-->
<!--                <el-table-column-->
<!--                        prop="id"-->
<!--                        label="操作"-->
<!--                        width="115">-->
<!--                    <template slot-scope="scope">-->
<!--                        <el-dropdown @command="dropdownCommand">-->
<!--                            <el-button size="mini">-->
<!--                                <i class="el-icon-setting"></i>-->
<!--                                操作-->
<!--                                <span class="el-icon-arrow-down"></span>-->
<!--                            </el-button>-->
<!--                            <el-dropdown-menu slot="dropdown">-->
<!--                                <el-dropdown-item :command="{type:'1',row:scope.row}">-->
<!--                                    获取小程序码-->
<!--                                </el-dropdown-item>-->
<!--                                <el-dropdown-item-->
<!--                                        :command="{type:'2',row:scope.row}">-->
<!--                                    修改-->
<!--                                </el-dropdown-item>-->
<!--                                <el-dropdown-item-->
<!--                                        :command="{type:'3',row:scope.row}">-->
<!--                                    删除-->
<!--                                </el-dropdown-item>-->
<!--                            </el-dropdown-menu>-->
<!--                        </el-dropdown>-->
<!--                    </template>-->
<!--                </el-table-column>-->
            </el-table>
            <br>
            <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNumber"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.totalCount">
            </el-pagination>
        </el-main>
        <!--//编辑管理员-->
        <form-dialog @close="handleClose('editForm')" :title="editTitle" :dialogVisible="visibleFormDialog">
            <template slot="content">
                <el-form :model="formData" ref="editForm" :rules="formRules" label-position="right" label-width="95px">
                    <el-form-item prop="code" label="编码">
                        <el-input maxlength="10" placeholder="编码"
                                  v-model="formData.code"
                                  auto-complete="off" tabindex="3" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="name" label="名称">
                        <el-input maxlength="100" placeholder="名称"
                                  v-model="formData.name"
                                  auto-complete="off" tabindex="3" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="spType" label="类型">
                        <el-radio-group v-model="formData.spType">
                            <el-radio :label="1" border>网吧</el-radio>
                            <el-radio :label="2" border>传单</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item prop="mobile" label="联系电话">
                        <el-input maxlength="100" placeholder="联系电话"
                                  v-model="formData.mobile"
                                  auto-complete="off" tabindex="4" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="address" label="详细地址">
                        <el-input maxlength="100" placeholder="详细地址"
                                  v-model="formData.address"
                                  auto-complete="off" tabindex="9" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="spStatus" label="状态">
                        <el-radio-group v-model="formData.spStatus">
                            <el-radio :label="1" border>正常</el-radio>
                            <el-radio :label="-1" border>停用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item prop="rewardType" label="返点类型">
                        <el-radio-group v-model="formData.rewardType">
                            <el-radio :label="1" border>现结</el-radio>
                            <el-radio :label="2" border>月结</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item prop="settleType" label="结算方式">
                        <el-radio-group v-model="formData.settleType">
                            <el-radio :label="1" border>按单量</el-radio>
                            <el-radio :label="2" border>按商品数量</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item prop="retFee" label="返点值">
                        <el-input maxlength="100" placeholder="返点值"
                                  v-model="formData.retFee"
                                  auto-complete="off" tabindex="9" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="price" label="员工餐价格">
                        <el-input maxlength="100" placeholder="员工餐价格"
                                  v-model="formData.price"
                                  auto-complete="off" tabindex="9" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="orderNum" label="排序数字">
                        <el-input maxlength="100" placeholder="排序数字"
                                  v-model="formData.orderNum"
                                  auto-complete="off" tabindex="9" type="number"></el-input>
                    </el-form-item>
                    <div class="flex flex-direction-row-reverse btn-group">
                        <el-button type="primary" @click="doEdit">确 定</el-button>
                        <el-button @click="closeModul">取 消</el-button>
                    </div>
                </el-form>

            </template>
        </form-dialog>
        <form-dialog @close="handleClose('codeForm')" title="商家小程序码" :dialogVisible="codeDialogVisible" width="40%">
            <template slot="content" class="loadingtext">
                <el-form  ref="codeForm" size="small" label-width="80px" align="center">

                    <img :src="formData.shop_qrcode" style="width:250px;height:250px" v-if="formData.shop_qrcode"/>
                    <p v-else style="width:100%;height:250px;line-height: 250px;text-align: center">加载中...</p>
                    <div class="flex flex-direction-row-reverse btn-group">
                        <el-button type="primary" @click="doDownload">下载</el-button>
                        <el-button @click="codeDialogVisible = false">取 消</el-button>
                    </div>
                </el-form>
            </template>
        </form-dialog>
    </el-container>

</template>

<script>
  import {mapGetters} from 'vuex'
  import formDialog from '../../components/formDialog'
  import {getReward,shopData} from '../../api/firmManage'
  import moment from 'moment'
  export default {
    name: "companyManagement",
    components: {formDialog},
    data() {
      return {
        tableData: [],
        pageInfo: {
          totalCount: 0,
          pageNumber: 1,
          pageSize: 10,
        },
        time:'',
        query: {

          shopId:'',
          type:''
        },
        visibleFormDialog:false,

        formData: {
          code:'',//商家code
          spType:'',//类型
          name:'',//名称
          mobile:'',//电话
          address:'',//地址
          // longitude:null,//
          // latitude:null,
          spStatus:'',//状态
          rewardType:'',//返点类型
          settleType:'',//结算方式
          retFee:'',//返点金额
          // shopQrCode:'',//二维码地址
          price:'',//价格
          orderNum:''//排序数字
        },
        editTitle:'',
        formRules: {
          code: [
            {required: true, message: '编码', trigger: 'blur'}
          ],
          retFee: [
            {required: true, message: '返点金额', trigger: 'blur'}
          ],
          price: [
            {required: true, message: '员工餐价格', trigger: 'blur'}
          ],
          orderNum: [
            {required: true, message: '排序数字', trigger: 'blur'}
          ],
          name: [
            {required: true, message: '名称', trigger: 'blur'}
          ],
          spType: [
            {required: true, message: '类型', trigger: 'blur'}
          ],
          rewardType: [
            {required: true, message: '返点类型', trigger: 'blur'}
          ],
          settleType: [
            {required: true, message: '结算方式', trigger: 'blur'}
          ],
          mobile: [
            {required: true,trigger: 'blur'}
          ],
          address: [
            {required: true, message: '详细地址', trigger: 'blur'}
          ],
          spStatus:[
            {required: true, message: '状态', trigger: 'blur'}
          ],
        },
        selectData:[],
        codeDialogVisible:false,
        shopErcode: {},
        shopDataArr:[]
      }
    },
    computed: {
      ...mapGetters([
        'userInfo'
      ]),
    },
    methods: {
      handleSearch() {
        this.pageInfo.pageNumber = 1;
        this.getReward()
      },
      async getReward() {
        try {
          let obj=!this.time?{}:{startTime:new Date(this.time[0]+' 00:00:00').getTime()/1000,endTime:new Date(this.time[1]+' 23:59:59').getTime()/1000}
          let query = Object.assign({}, this.pageInfo, this.query,obj);
          console.log(350,query)
          let formData = {}
          for (let i in query) {
            if (query[i] !== '' && i !== 'total' && query[i] !== -1) {
              formData[i] = query[i]
            }
          }
          let {data} = await getReward(formData);
          console.log(381,data)
          // let {data} = resonse;
          this.tableData = data.list;
          this.pageInfo.totalCount = data.totalCount;
        } catch (e) {
          console.log(e)
        }
      },
      async shopData(){
        try {
          let {data} = await shopData();
          console.log(375,data)
          this.shopDataArr=data.list;
        }catch (e) {

        }
      },
      clearSearch() {
        this.query={shopId:'',
          type:''},
          this.time='',
          this.handleSearch()
      },
      handleSizeChange(val) {
        this.pageInfo.pageSize = val
        this.pageInfo.page = 1
        this.getReward()
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.pageInfo.pageNumber = val
        this.getReward()
      },
      handleClose(form) {
        this.$refs[form].resetFields();
        this.visibleFormDialog = false;
        this.codeDialogVisible=false;
      },
      handleAdd() {
        this.editTitle = '新建商家';
        this.visibleFormDialog = true;
        this.formData={};
        this.$refs.searchForm.resetFields();
      },
      handleEdit(row) {
        // console.log(426,row)
        this.editTitle = '修改商家';
        this.formData=row;
        this.formData.spType=row.sptype;
        this.formData.spStatus=row.sp_status;
        this.formData.rewardType=Number(row.reward_type);
        this.formData.settleType=Number(row.settle_type);
        this.formData.retFee=row.ret_fee;
        this.formData.orderNum=row.order_num;
        console.log(435,this.formData)
        this.visibleFormDialog = true;
      },
      //修改
      async doEdit(){
        // console.log(271,this.formData)
        this.$refs['editForm'].validate(async (valid)=>{
          if(valid){
            try {
              this.formData.id?await shopEdit(this.formData):await shopAdd(this.formData);
              this.$message({
                type: 'success',
                message: '操作成功!'
              });
              this.visibleFormDialog = false;
              this.$refs['editForm'].resetFields();
              this.getReward()
            }catch (e) {

            }
          }
        })
      },
      //删除
      async doDelete(row){
        console.log(308,row);
        this.$confirm('删除后无法恢复，确定删除'+row.name+'吗?', '确定', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          try {
            await shopDeleteOne(row.id);
            this.$message({
              type: 'success',
              message: '更新成功!'
            });
            this.getReward()
          }catch (e) {
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      },
      handleSelectionChange(val){
        console.log(334,val);
        this.selectData=val;
      },
      closeModul(){
        this.visibleFormDialog = false;
        this.getReward();
      },
      async doDelMore(){
        let ids = [];
        this.selectData.forEach(function (val) {
          ids.push(val.id);
        });
        this.$confirm('删除后无法恢复，确定删除选中选项吗?', '确定', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          try {
            await shopDeleteMore({ids: ids.toString()});
            this.$message({
              type: 'success',
              message: '更新成功!'
            });
            this.getReward()
          }catch (e) {
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      },
      async getErcode(row){
        console.log(483,row)
        this.formData=row;
        this.codeDialogVisible=true;
        // this.shopErcode={};
        // try {
        //   this.codeDialogVisible=true;
        //   let {data}=await getErcode(row.id);
        //   this.shopErcode=data;
        // }catch (e) {
        //
        // }
      },
      doDownload(){
        let self = this;
        let a = document.createElement('a');
        a.download = self.shopName || 'wxcode';
        // 设置图片地址
        a.href = self.shopErcode.url;
        a.click();
      },
      async dropdownCommand(command) {//监听下拉框事件
       console.log(529,command);
       if(command.type==1){
         await this.getErcode(command.row);
         // this.formData=command.row;
       }else if(command.type==2){
         await this.handleEdit(command.row);
       }else{
         await this.doDelete(command.row);
       }
      },
    },
    mounted() {
      this.getReward();
      this.shopData();
    }
  }
</script>

<style lang="less">
    .btn-group {
        button {
            margin-left: 20px;
        }

    }
    .el-input--prefix .el-input__inner {
        padding-left: 46px;
    }
    .el-input__prefix{
        left: 15px;
        color: #303133;
        i{
            font-style:normal;
        }
    }

    .icon-cursor {
        cursor: pointer;
        margin-right: 10px;
        &:hover {
            color: #348EED;
        }
    }

    .avatar-uploader {
        .el-upload {
            border: 1px solid #DCDFE6;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 72px;
            display: block;
            &:hover {
                border-color: #409EFF;
            }
        }
        .cw-icon-tupian {
            font-size: 24px;
            color: #c0c4cc;
            width: 72px;
            height: 72px;
            line-height: 72px;
            text-align: center;
        }
        .avatar {
            width: 72px;
            height: 72px;
            display: block;
        }
    }

</style>
